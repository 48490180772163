/*====================================
	1. Color
====================================*/
.path-timetable .article {
  max-width: 1000px;
  margin: 8px auto 80px;
}

.path-timetable .article .pdf {
  margin-bottom: 24px;
  padding-right: 8px;
  text-align: right;
}

.path-timetable .article .pdf a {
  font-size: clamp(14px, 2vw, 16px);
  position: relative;
}

.path-timetable .article .pdf a::after {
  content: "";
  display: inline-block;
  background: url(/img/common/ico_pdf.svg) top left no-repeat;
  width: 23px;
  padding-top: 31px;
  margin-bottom: -10px;
  margin-left: 8px;
}

.path-timetable .article .article-nav {
  display: flex;
  margin-bottom: 64px;
}

.path-timetable .article .article-nav .nav__item {
  flex: 1 1 auto;
  text-align: center;
  padding: 12px 8px;
  position: relative;
  cursor: pointer;
  transition: .3s;
}

.path-timetable .article .article-nav .nav__item::after {
  position: absolute;
  bottom: -12px;
  left: 0;
  content: '';
  width: 100%;
  height: 4px;
  background: #E5EAFF;
}

.path-timetable .article .article-nav .nav__item span {
  display: block;
}

.path-timetable .article .article-nav .nav__item span.en {
  font-family: 'Montserrat','serif';
  font-weight: 800;
  font-size: clamp(16px, 3vw, 24px);
}

.path-timetable .article .article-nav .nav__item span.jp {
  font-weight: 500;
  font-size: clamp(16px, 3vw, 26px);
}

.path-timetable .article .article-nav .nav__item.current {
  background: #001976;
  color: #FFFFFF;
}

.path-timetable .article .article-nav .nav__item.current a {
  transition: .3s;
  color: #FFFFFF;
}

.path-timetable .article .article-nav .nav__item.current::after {
  background: #001976;
}

.path-timetable .article .article-nav .nav__item:hover {
  transition: .3s;
  background: #001976;
  color: #FFFFFF;
}

.path-timetable .article .article-nav .nav__item:hover a {
  transition: .3s;
  color: #FFFFFF;
}

.path-timetable .article .article-nav .nav__item:hover::after {
  transition: .3s;
  background: #001976;
}

.path-timetable .article .article-wrap .btn {
  display: flex;
  justify-content: center;
  font-size: clamp(14px, 2vw, 20px);
  padding: 0 12px;
  gap: 12px;
}

.path-timetable .article .article-wrap .btn a {
  flex: 1 0 150px;
  text-align: center;
  display: block;
  border: 2px solid #001976;
  padding: 12px 0;
}

.path-timetable .article .article-wrap .btn a::after {
  content: "";
  display: inline-block;
  width: 18px;
  height: 8px;
  border-right: 2px solid #001976;
  border-bottom: 1.5px solid #001976;
  transform: skew(45deg, 0);
  margin-top: 8px;
  margin-left: 4px;
}

.path-timetable .article .article-wrap .program {
  margin-top: 40px;
}

.path-timetable .article .article-wrap .program .program-head {
  border-top: 2px solid #001976;
  background: #E5EAFF;
  padding: 10px 20px;
}

.path-timetable .article .article-wrap .program .program-head .time {
  font-size: clamp(20px, 3vw, 28px);
  font-weight: 700;
  margin-bottom: 4px;
}

.path-timetable .article .article-wrap .program .program-head .title-wrap {
  display: flex;
  flex-direction: column;
}

.path-timetable .article .article-wrap .program .program-head .title-wrap a:hover {
  text-decoration: underline;
}

.path-timetable .article .article-wrap .program .program-head .title-wrap .title {
  font-size: clamp(20px, 3vw, 28px);
  margin-bottom: 4px;
}

.path-timetable .article .article-wrap .program .program-head .title-wrap .corp {
  font-size: 14px;
}

.path-timetable .article .article-wrap .program .program-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px 0 16px;
  gap: 16px;
}

.path-timetable .article .article-wrap .program .program-img .image-wrap {
  flex: 0 0 160px;
}

.path-timetable .article .article-wrap .program .program-img .image-wrap .image {
  margin-bottom: 4px;
}

.path-timetable .article .article-wrap .program .program-img .image-wrap .image img {
  vertical-align: bottom;
}

.path-timetable .article .article-wrap .program .program-link {
  margin-bottom: 24px;
}

.path-timetable .article .article-wrap .program .program-link .program-link-wrap {
  margin-bottom: 16px;
  padding: 0 16px;
  display: flex;
  gap: 24px;
}

.path-timetable .article .article-wrap .program .program-link .program-link-wrap .blog::before {
  content: "";
  display: inline-block;
  background: url(/img/common/ico_blog.svg) top left no-repeat;
  width: clamp(30px, 2vw, 34px);
  padding-top: clamp(30px, 2vw, 34px);
  margin-bottom: -10px;
  margin-right: 8px;
}

.path-timetable .article .article-wrap .program .program-link .program-link-wrap .message::before {
  content: "";
  display: inline-block;
  background: url(/img/common/ico_message.svg) top left no-repeat;
  width: clamp(30px, 2vw, 34px);
  padding-top: clamp(30px, 2vw, 34px);
  margin-bottom: -10px;
  margin-right: 8px;
}

.path-timetable .article .article-wrap .program .program-link .program-mail {
  padding: 0 16px;
}

.path-timetable .article .article-wrap .program .program-link .program-mail::before {
  content: "";
  display: inline-block;
  background: url(/img/common/ico_mail.svg) top left no-repeat;
  width: clamp(30px, 2vw, 34px);
  padding-top: clamp(30px, 2vw, 34px);
  margin-bottom: -10px;
  margin-right: 8px;
}

.path-timetable .article .article-wrap .program .program-sns {
  margin-bottom: 16px;
}

.path-timetable .article .article-wrap .program .program-sns .sns-items {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.path-timetable .article .article-wrap .program .program-sns .sns-items__item {
  flex: 0 0 36px;
}

.path-timetable .article .article-wrap .program .program-corner {
  padding: 0 16px;
}

.path-timetable .article .article-wrap .program .program-corner .title {
  text-align: center;
  background: #F5F5F5;
  font-size: clamp(18px, 3vw, 24px);
  padding: 4px 0;
  font-weight: 500;
}

.path-timetable .article .article-wrap .program .program-corner .table {
  padding: 4px 0;
  font-size: clamp(14px, 2vw, 16px);
  border-bottom: 2px solid #E6E6E6;
}

.path-timetable .article .article-wrap .program .program-corner .table .table__dt {
  margin-bottom: 4px;
}

.path-timetable .article .article-wrap .program .program-corner .table .table__dd a {
  text-decoration: underline;
}

/****************************************************
 PC scss
****************************************************/
@media screen and (min-width: 768px) {
  .path-timetable .article .article-nav {
    display: flex;
    margin-bottom: 80px;
  }
  .path-timetable .article .article-nav .nav__item {
    flex: 1 1 calc(100% / 7);
    text-align: center;
    padding: 16px 40px;
    position: relative;
  }
  .path-timetable .article .article-nav .nav__item::after {
    position: absolute;
    bottom: -20px;
    left: 0;
    content: '';
    width: 100%;
    height: 4px;
    background: #E5EAFF;
  }
  .path-timetable .article .article-nav .nav__item span {
    display: block;
  }
  .path-timetable .article .article-nav .nav__item span.en {
    font-family: 'Montserrat','serif';
    font-weight: 800;
    font-size: clamp(20px, 3vw, 24px);
  }
  .path-timetable .article .article-nav .nav__item span.jp {
    font-weight: 500;
    font-size: clamp(20px, 3vw, 26px);
  }
  .path-timetable .article .article-nav .nav__item.current {
    background: #001976;
    color: #FFFFFF;
  }
  .path-timetable .article .article-nav .nav__item.current::after {
    background: #001976;
  }
  .path-timetable .article .article-nav .nav__item:hover {
    cursor: pointer;
    background: #001976;
    color: #FFFFFF;
    transition: .3s;
  }
  .path-timetable .article .article-nav .nav__item:hover::after {
    transition: .3s;
    background: #001976;
  }
  .path-timetable .article .pdf {
    padding-right: 0;
  }
  .path-timetable .article .pdf a:hover {
    text-decoration: underline;
  }
  .path-timetable .article .article-wrap .btn {
    gap: 40px;
  }
  .path-timetable .article .article-wrap .btn a {
    flex: 0 0 300px;
  }
  .path-timetable .article .article-wrap .btn a:hover {
    border: 2px solid #001976;
    background: #001976;
    color: #FFFFFF;
    transition: .3s;
  }
  .path-timetable .article .article-wrap .btn a:hover::after {
    transition: .3s;
    border-right: 3px solid #FFFFFF;
    border-bottom: 2.5px solid #FFFFFF;
  }
  .path-timetable .article .article-wrap .program .program-head {
    display: flex;
    border-top: 2px solid #001976;
    background: #E5EAFF;
    padding: 10px 20px;
  }
  .path-timetable .article .article-wrap .program .program-head .time {
    flex: 0 0 240px;
    font-size: clamp(20px, 3vw, 28px);
    font-weight: 700;
    margin-bottom: 4px;
  }
  .path-timetable .article .article-wrap .program .program-head .title-wrap {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
  }
  .path-timetable .article .article-wrap .program .program-head .title-wrap .title {
    font-size: clamp(20px, 3vw, 28px);
    margin-bottom: 4px;
    margin-right: auto;
  }
  .path-timetable .article .article-wrap .program .program-head .title-wrap .corp {
    flex: 0 0 auto;
    align-self: flex-end;
    text-align: right;
    font-size: 14px;
    margin-left: 8px;
  }
  .path-timetable .article .article-wrap .program .program-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 24px 0 16px 240px;
    gap: 16px;
  }
  .path-timetable .article .article-wrap .program .program-img .image-wrap {
    flex: 0 0 160px;
  }
  .path-timetable .article .article-wrap .program .program-img .image-wrap a:hover {
    text-decoration: underline;
  }
  .path-timetable .article .article-wrap .program .program-img .image-wrap .image {
    margin-bottom: 4px;
  }
  .path-timetable .article .article-wrap .program .program-img .image-wrap .image img {
    vertical-align: bottom;
  }
  .path-timetable .article .article-wrap .program .program-link {
    margin-bottom: 8px;
    padding-left: 240px;
    display: flex;
    gap: 24px;
  }
  .path-timetable .article .article-wrap .program .program-link .program-link-wrap {
    padding: 0 0;
  }
  .path-timetable .article .article-wrap .program .program-link .program-mail {
    padding: 0 0;
  }
  .path-timetable .article .article-wrap .program .program-link a:hover {
    text-decoration: underline;
  }
  .path-timetable .article .article-wrap .program .program-sns {
    padding-left: 240px;
    margin-bottom: 24px;
  }
  .path-timetable .article .article-wrap .program .program-sns .sns-items {
    justify-content: flex-start;
  }
  .path-timetable .article .article-wrap .program .program-sns .sns-items__item {
    flex: 0 0 36px;
  }
  .path-timetable .article .article-wrap .program .program-sns .sns-items__item a {
    display: block;
    transition: all 0.2s ease-in-out;
  }
  .path-timetable .article .article-wrap .program .program-sns .sns-items__item a:hover {
    transform: scale(1.2);
  }
  .path-timetable .article .article-wrap .program .program-corner {
    padding: 0 0;
  }
  .path-timetable .article .article-wrap .program .program-corner .title {
    padding: 4px 0;
  }
  .path-timetable .article .article-wrap .program .program-corner .table {
    padding: 8px 0;
    display: flex;
    border-bottom: 2px solid #E6E6E6;
  }
  .path-timetable .article .article-wrap .program .program-corner .table .table__dt {
    flex: 0 0 240px;
    text-align: center;
  }
  .path-timetable .article .article-wrap .program .program-corner .table .table__dd .txt-wrap {
    display: flex;
  }
  .path-timetable .article .article-wrap .program .program-corner .table .table__dd .txt-wrap .txt {
    margin-right: 16px;
  }
  .path-timetable .article .article-wrap .program .program-corner .table .table__dd .txt-wrap a:hover {
    text-decoration: none;
  }
}
