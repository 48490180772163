@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__mixin.scss";
@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__variable.scss";
.path-timetable {
  .article {
    max-width: 1000px;
    margin: 8px auto 80px;
    .pdf {
      margin-bottom: 24px;
      padding-right: 8px;
      text-align: right;
      a {
        font-size: clamp(14px,2vw,16px);
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          background: url(/img/common/ico_pdf.svg) top left  no-repeat;
          width: 23px;
          padding-top: 31px;
          margin-bottom: -10px;
          margin-left: 8px;
        }
      }
    }
    .article-nav {
      display: flex;
      margin-bottom: 64px;
      .nav__item {
        flex: 1 1 auto;
        text-align: center;
        padding: 12px 8px;
        position: relative;
        &::after {
          position: absolute;
          bottom: -12px;
          left: 0;
          content: '';
          width: 100%;
          height: 4px;
          background: $COLOR_SUB;
        }
        span {
          display: block;
          &.en {
            font-family: 'Montserrat','serif';
            font-weight: 800;
            font-size: clamp(16px,3vw,24px);
          }
          &.jp {
            font-weight: 500;
            font-size: clamp(16px,3vw,26px);
          }
        }
        cursor: pointer;
        transition: .3s;
        &.current {
          background: $COLOR_MAIN;
          color: $GRAY_000;
          a {
            transition: .3s;
            color: $GRAY_000;
          }
          &::after {
            background: $COLOR_MAIN;
          }
        }
        &:hover {
          transition: .3s;
          a {
            transition: .3s;
            color: $GRAY_000;
          }
          background: $COLOR_MAIN;
          color: $GRAY_000;
          &::after {
            transition: .3s;
            background: $COLOR_MAIN;
          }
        }
      }
    }
    .article-wrap {
      .btn {
        display: flex;
        justify-content: center;
        font-size: clamp(14px,2vw,20px);
        padding: 0 12px;
        gap: 12px;
        a {
          flex: 1 0 150px;
          text-align: center;
          display: block;
          border: 2px solid $COLOR_MAIN;
          padding: 12px 0;
          &::after {
            content: "";
            display: inline-block;
            width: 18px;
            height: 8px;
            border-right: 2px solid $COLOR_MAIN;
            border-bottom: 1.5px solid $COLOR_MAIN;
            transform: skew(45deg, 0);
            margin-top: 8px;
            margin-left: 4px;
          }
        }
      }
      .program {
        margin-top: 40px;
        .program-head {
          border-top: 2px solid $COLOR_MAIN;
          background: $COLOR_SUB;
          padding: 10px 20px;
          .time {
            font-size: clamp(20px,3vw,28px);
            font-weight: 700;
            margin-bottom: 4px;
          }
          .title-wrap {
            display: flex;
            flex-direction: column;
            a {
              &:hover {
                text-decoration: underline;
              }
            }
            .title {
              font-size: clamp(20px,3vw,28px);
              margin-bottom: 4px;
            }
            .corp {
              font-size: 14px;
            }
          }
        }
        .program-img {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: 24px 0 16px;
          gap: 16px;
          .image-wrap {
            flex: 0 0 160px;
            .image {
              margin-bottom: 4px;
              img {
                vertical-align: bottom;
              }
            }
          }
        }
        .program-link {
          margin-bottom: 24px;
          .program-link-wrap {
            margin-bottom: 16px;
            padding: 0 16px;
            display: flex;
            gap: 24px;
            .blog {
              &::before {
                content: "";
                display: inline-block;
                background: url(/img/common/ico_blog.svg) top left  no-repeat;
                width:  clamp(30px,2vw,34px);
                padding-top:  clamp(30px,2vw,34px);
                margin-bottom: -10px;
                margin-right: 8px;
              }
            }
            .message {
              &::before {
                content: "";
                display: inline-block;
                background: url(/img/common/ico_message.svg) top left  no-repeat;
                width:  clamp(30px,2vw,34px);
                padding-top:  clamp(30px,2vw,34px);
                margin-bottom: -10px;
                margin-right: 8px;
              }
            }
          }
          .program-mail {
            padding: 0 16px;
            &::before {
              content: "";
              display: inline-block;
              background: url(/img/common/ico_mail.svg) top left  no-repeat;
              width:  clamp(30px,2vw,34px);
              padding-top:  clamp(30px,2vw,34px);
              margin-bottom: -10px;
              margin-right: 8px;
            }
          }
        }
        .program-sns {
          margin-bottom: 16px;
          .sns-items {
            display: flex;
            justify-content: center;
            gap: 16px;
            &__item {
              flex: 0 0 36px;
            }
          }
        }
        .program-corner {
          padding: 0 16px;
          .title {
            text-align: center;
            background: $GRAY_100;
            font-size: clamp(18px,3vw,24px);
            padding: 4px 0;
            font-weight: 500;
          }
          .table {
            padding: 4px 0;
            font-size: clamp(14px,2vw,16px);
            border-bottom: 2px solid $GRAY_200;
            .table__dt {
              margin-bottom: 4px;
            }
            .table__dd {
              a {
                text-decoration: underline;
              }
            }
          }
        }

      }
    }
  }

}





/****************************************************
 PC scss
****************************************************/
@media screen and (min-width:768px) {
  .path-timetable {
    .article {
      .article-nav {
        display: flex;
        margin-bottom: 80px;
        .nav__item {
          flex: 1 1 calc(100% / 7);
          text-align: center;
          padding: 16px 40px;
          position: relative;
          &::after {
            position: absolute;
            bottom: -20px;
            left: 0;
            content: '';
            width: 100%;
            height: 4px;
            background: $COLOR_SUB;
          }
          span {
            display: block;
            &.en {
              font-family: 'Montserrat','serif';
              font-weight: 800;
              font-size: clamp(20px,3vw,24px);
            }
            &.jp {
              font-weight: 500;
              font-size: clamp(20px,3vw,26px);
            }
          }
          &.current {
            background: $COLOR_MAIN;
            color: $GRAY_000;
            &::after {
              background: $COLOR_MAIN;
            }
          }
          &:hover {
            cursor: pointer;
            background: $COLOR_MAIN;
            color: $GRAY_000;
            transition: .3s;
            &::after {
              transition: .3s;
              background: $COLOR_MAIN;
            }
          }
        }
      }
      .pdf {
        padding-right: 0;
        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .article-wrap {
        .btn {
          gap: 40px;
          a {
            flex: 0 0 300px;
            &:hover {
              border: 2px solid $COLOR_MAIN;
              background: $COLOR_MAIN;
              color: $GRAY_000;
              transition: .3s;
              &::after {
                transition: .3s;
                border-right: 3px solid $GRAY_000;
                border-bottom: 2.5px solid $GRAY_000;
              }
            }
          }
        }
        .program {
          .program-head {
            display: flex;
            border-top: 2px solid $COLOR_MAIN;
            background: $COLOR_SUB;
            padding: 10px 20px;
            .time {
              flex: 0 0 240px;
              font-size: clamp(20px,3vw,28px);
              font-weight: 700;
              margin-bottom: 4px;
            }
            .title-wrap {
              display: flex;
              flex-direction: row;
              flex: 1 1 auto;
              .title {
                font-size: clamp(20px,3vw,28px);
                margin-bottom: 4px;
                margin-right: auto;
              }
              .corp {
                flex: 0 0 auto;
                align-self: flex-end;
                text-align: right;
                font-size: 14px;
                margin-left: 8px;
              }
            }
          }
          .program-img {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 24px 0 16px 240px;
            gap: 16px;
            .image-wrap {
              flex: 0 0 160px;
              a {
                &:hover {
                  text-decoration: underline;
                }
              }
              .image {
                margin-bottom: 4px;
                img {
                  vertical-align: bottom;
                }
              }
            }
          }
          .program-link {
            margin-bottom: 8px;
            padding-left: 240px;
            display: flex;
            gap: 24px;
            .program-link-wrap {
              padding: 0 0;
            }
            .program-mail {
              padding: 0 0;
            }
            a {
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .program-sns {
            padding-left: 240px;
            margin-bottom: 24px;
            .sns-items {
              justify-content: flex-start;
              &__item {
                flex: 0 0 36px;
                a {
                  display: block;
                  transition: all 0.2s ease-in-out;
                  &:hover {
                    transform: scale(1.2);
                  }
                }
              }
            }
          }
          .program-corner {
            padding: 0 0;
            .title {
              padding: 4px 0;
            }
            .table {
              padding: 8px 0;
              display: flex;
              border-bottom: 2px solid $GRAY_200;
              .table__dt {
                flex: 0 0 240px;
                text-align: center;
              }
              .table__dd {
                .txt-wrap {
                  display: flex;
                  .txt {
                    margin-right: 16px;
                  }
                  a {
                    &:hover {
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
